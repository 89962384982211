import { ChangeEvent, MouseEvent } from "react";

export function isDescendant(parent: string, child?: EventTarget | null) {
  if (!child) return false;

  // @ts-ignore
  var node: HTMLElement = child.parentNode;
  while (node != null && node.tagName !== "document") {
    if (node.className?.split(" ").includes(parent)) {
      return true;
    }
    // @ts-ignore
    node = node.parentNode;
  }
  return false;
}

export function isDescendantOfEl(parent: Node, child: Node): boolean {
  let node: Node | null = child.parentNode;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
}

export function findNearestParent(element: Element, selector: string): Element | undefined {
  if (element.matches(selector)) return element;
  if (element.parentElement) return findNearestParent(element.parentElement, selector);
  return undefined;
}

export function getActiveScrollParent(node) {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight && node.offsetHeight && node.scrollTop !== 0) {
    return node;
  } else {
    return !!node.parentNode ? getActiveScrollParent(node.parentNode) : null;
  }
}

export const preventPropagation = (e: MouseEvent<HTMLElement> | ChangeEvent) => {
  e.preventDefault();
  e.stopPropagation();
};
